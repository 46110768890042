export const data = [
  {
    id: "0",
    question: "What would you best describe yourself as?",
    variants: [
      {option: `Patient`, value:`BDG`},
      {option: `Optimist`, value:`W`},
      {option: `Activist`, value:`I`}]
  },
  {
    id: "1",
    question: "Would you buy your toothpaste in 2 years supply if there was a 5 percent discount?",
    variants: [
      {option: `Absolutely`, value:`BDIG`},
      {option: `Nope`, value:`W`}]
  },
  {
    id: "2",
    question: "What would you say is a company’s mission?",
    variants: [
      {option: `Maximize shareholder value`, value:`BDG`},
      {option: `Further innovation`, value:`W`}]
  },
  {
    id: "3",
    question: "You have no faith with company’s management, so you...",
    variants: [
      {option: `Ignore the said company and invest in a competitor.`, value:`BWDG`},
      {option: `Buy a stake in the company & change its corporate policies`, value:`I`},]
  },
  {
    id: "4",
    question: "You have no time to read balance sheets of individual companies so you",
    variants: [
      {option: `Invest in passively managed broad index funds`, value:`B`},
      {option: `I love digging into balance sheets to find hidden gems `, value:`WDIG`}]
  },
  {
    id: "5",
    question: "Would you rather",
    variants: [
       {option: `Wait ten years to 10X on your investment`, value:`WDI`},
       {option: `Enjoy 3 percent dividend annual returns `, value:`BG`}]
  },
  {
    id: "6",
    question: "You are up to date on macroeconomics and geo political news",
    variants: [
       {option: `It is all too complicated`, value:`BWIG`},
       {option: `Yes, I study currency and commodity movements regularly `, value:`D`}]
  }
];
