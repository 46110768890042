import "./styles.css";
import React, { useState } from "react";
import { data } from "./data";
import buffet from './buffet.jpg'
import icahn from './icahn.jpg'
import dalio from './dalio.jpg'
import graham from './graham.jpg'
import wood from './wood.jpg'

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";


export default function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [myAnswer, setMyAnswer] = useState("");
  const [score, setScore] = useState('');
  const [finish, setFinish] = useState(false);
  const [show, setShow] = useState(false);
  const [clickAnswer, setClickAnswer] = useState(false);
  const [investor, setInvestor] = useState('');
  const [investorImage, setInvestorImage] = useState('');
  const [investorBio, setInvestorBio] = useState('');
  const shareUrl = 'https://quiz.getdami.io/';

  const list = {
    'B': 'Warren Buffet',
    'W': 'Cathie Wood',
    'D': 'Ray Dalio',
    'G': 'Benjamin Graham',
    'I': 'Carl Icahn'
    }

  const investorsList = [
    {
      name: `Cathie Wood`,
      img: wood ,
      bio: `Cathie Wood is a star stock-picker and founder of $60 billion (assets) ARK Invest, which invests in innovations like self-driving cars and genomics. After stints at other investment firms, Wood created ARK in 2014 hoping to package active stock portfolios in an ETF format.
      Her flagship $23 billion (assets) Ark Innovation Fund has returned an average of nearly 45% annually over the past five years.`
    },
    {
      name: `Warren Buffet`,
      img: buffet,
      bio: `Known as the "Oracle of Omaha," Warren Buffett is one of the most successful investors of all time. His net worth stands at about $103 billion.
            Buffett runs Berkshire Hathaway, which owns dozens of companies, including insurer Geico, battery maker Duracell and restaurant chain Dairy Queen.
            Buffett's investing style of discipline, patience, and value has consistently outperformed the market for decades`
    },
    {
      name: `Carl Icahn`,
      img: icahn,
      bio: `Icahn takes large stakes in companies that he believes will appreciate via changes to corporate policy and he then pressures management to make changes that he believes will benefit shareholders.
      He was one of the first activist shareholders and is credited with making that investment strategy mainstream for hedge funds. He has a net worth of about $17 billion`
    },
    {
      name: `Ray Dalio`,
      img: dalio,
      bio: `Ray Dalio is the founder of the world's biggest hedge fund firm, Bridgewater Associates, which manages $154 billion.
      Dalio is regarded as one of the greatest innovators in the finance world, having popularized many commonly used practices, such as risk parity, currency overlay, portable alpha and global inflation-indexed bond management.`
    },
    {
      name: `Benjamin Graham`,
      img: graham,
      bio: `The essence of Graham's value investing is that any investment should be worth substantially more than an investor has to pay for it.
      He believed in fundamental analysis and sought out companies with strong balance sheets, or those with little debt, above-average profit margins, and ample cash flow.`
    }
]

  const addToScore = (variant) => {
      setMyAnswer(variant);
      setScore(score + variant.value)
  };

const getMax = (str) => {
   var max = 0,
       maxChar = '';
    str.split('').forEach(function(char){
      if(str.split(char).length > max) {
          max = str.split(char).length;
          maxChar = char;
       }
    });
    return maxChar;
  };

  const reset = () => {
    setShow(false);
    setClickAnswer(false);
  };

  const finishHandler = () => {
    if (currentQuestion === data.length - 1) {
      setFinish(true);
      let investor_name = getMax(score)
      setInvestor(list[investor_name])
      setInvestorBio(investorsList.find( i => i.name === list[investor_name]).bio)
      setInvestorImage(investorsList.find( i => i.name === list[investor_name]).img)
    }
  };

  const startOver = () => {
    setCurrentQuestion(0);
    setFinish(false);
    setMyAnswer("");
    setScore(0);
  };

  if (finish) {
    return (
      <div className="container m-4 p-4 mx-auto h-min-screen grid grid-rows-1 grid-cols-1 items-center">
        <div className="wrapper items-center">
          <h3 className="m-4 p-2 h-30 text-center text-2xl font-bold">
            {`Your investor profile is ${investor}`}
          </h3>
            <div class=" grid grid-cols-3 gap-4 content-start ...">
            <div></div>
            <img className="content-center object-contain h-130 w-130"
             src={investorImage}
             alt="logo"/>
            <div></div>
          </div>
          <h3 className="m-4 p-2 h-30 text-center text-2xl font-bold">
          {investorBio}
          </h3>
            <button
              className="w-full h-14 mt-2 px-2 rounded-lg bg-gray-600 text-pink-400 font-bold hover:bg-gray-800 hover:text-pink-600"
              onClick={() => startOver()}
            >
              Take the quiz again
            </button>
            <footer className="m-4 text-center">
              <div className="text-pink-400">
                Share this quiz with your friends to find out their investing style
              </div>
            <div>
            <FacebookShareButton
              url={shareUrl}
              quote={'Take your investor profile quiz'}
              hashtag={'#investing'}
            >
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>


            <TwitterShareButton
              url={shareUrl}
              quote={'Take your investor profile quiz'}
              hashtag={'#investing'}
            >
              <TwitterIcon size={40} round={true} />
            </TwitterShareButton>

            <WhatsappShareButton
              url={shareUrl}
              quote={'Take your investor profile quiz'}
              hashtag={'#investing'}
            >
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
            </div>
            <a
              className="text-pink-400"
              href="https://getdami.io"
              target="_blank"
              rel="noreferrer"
            >
                Learn more @ Dami{" "}

            </a>{" "}
            </footer>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container m-4 p-4 mx-auto h-min-screen grid grid-rows-1 grid-cols-1 items-center">
        <div className="wrapper">
          <h2 className="m-4 p-2 h-30 text-center text-2xl font-bold">
            {data[currentQuestion].question}
          </h2>
          {data[currentQuestion].variants.map((variant) => (
            <div className="m-2 h-14 border-2 border-black mx-auto text-center">
              <p
                key={variant.id}
                className={`variant ${
                  myAnswer === variant
                    ? myAnswer === data[currentQuestion].answer
                      ? "correctAnswer"
                      : "incorrectAnswer"
                    : null
                }`}
                onClick={() => addToScore(variant)}
              >
                {variant.option}
              </p>
            </div>
          ))}


          {currentQuestion < data.length - 1 && (
            <button
              className="w-full h-14 mt-2 px-2 rounded-lg bg-gray-600 text-pink-400 font-bold hover:bg-gray-800 hover:text-pink-600"
              onClick={() => {
                setCurrentQuestion(currentQuestion + 1);
                reset();
              }}
            >
              NEXT
            </button>

          )}

          {currentQuestion === data.length - 1 && (
            <button
              className="w-full h-14 mt-2 px-2 rounded-lg bg-gray-600 text-pink-400 font-bold hover:bg-gray-800 hover:text-pink-600  py-2 px-4"
              onClick={() => finishHandler()}
            >
              FINISH
            </button>
          )}

          <footer className="m-4 text-center">
            <div className="text-pink-400">
              Share this quiz with your friends to find out their investing style
            </div>
          <div>
          <FacebookShareButton
            url={shareUrl}
            quote={'Take your investor profile quiz'}
            hashtag={'#investing'}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>


          <TwitterShareButton
            url={shareUrl}
            quote={'Take your investor profile quiz'}
            hashtag={'#investing'}
          >
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={shareUrl}
            quote={'Take your investor profile quiz'}
            hashtag={'#investing'}
          >
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          </div>
          <a
            className="text-pink-400"
            href="https://getdami.io"
            target="_blank"
            rel="noreferrer"
          >
              Learn more @ Dami{" "}

          </a>{" "}
          </footer>

        </div>
      </div>
    );
  }
}
